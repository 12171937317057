import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import { graphql, Link } from "gatsby"

export default ({ data }) => (
  <Layout>
    <main role="main">
      <div
        className="section section-md section-image overlay-dark text-white overflow-hidden"
        style={{ backgroundImage: `url("../hero-2.jpg")` }}
      ></div>

      <section className="section ">
        <div className="container">
          <div className="row align-items-center justify-content-around">
            <div className="col-md-6 col-xl-6 my-5 mt-sm-0 d-none d-xl-block">
              <img
                className="rounded overlay-dark position-relative img-fluid effect-img-1"
                src="/about-1.jpg"
                alt=""
              ></img>
              <img
                className="rounded overlay-dark position-absolute img-fluid effect-img-2"
                src="/about-2.jpg"
                alt=""
              ></img>
            </div>
            <div className="col-md-6 col-xl-5 text-center text-md-left">
              <h2 className="mb-5">
                Algorithmic driven,<br></br>
                <span className="font-weight-bold">Rigorous selection.</span>
              </h2>
              <p className="lead">
                <span className="font-weight-bold">Topbrokers</span> evaluates
                each individual broker using our top five qualifying metrics:
              </p>
              <blockquote className="blockquote text-center">
                reviews, industry experience, education, knowledge, content
                contribution.
              </blockquote>
              <p></p>
              <p className="lead">
                We then get to know and understand our topbrokers core values
                and business proposition before recommending them on our site.
              </p>
              <p></p>
              <p className="lead">
                Our future service plans include optimising our client
                experience through "personality matching algorithms". A
                scientifically validated personality and cognitive assessment
                based on neuro-science forms the blueprint of this match. This
                is done through an online questionnaire and allows us to better
                match you with the ideal{" "}
                <span className="font-weight-bold">Topbrokers</span>.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section ">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <p className="lead mt-4">
                The site was created to help direct you to the right broker to
                service your needs. There are literally 100's of home loan
                comparison sites which just compare rates and features. This can
                be very overwhelming when considering there are over 5,000+ home
                loan products on the evergrowing home loan market.
              </p>
              <p className="lead mt-4">
                Our site will analyse over 12,000 professional brokers and
                provide you with the 10 topbrokers in your local area. Our
                "personality matching tool" -coming soon will help match you
                with the ideal broker. We believe this is extremely important
                especially when building long term trusting relationships.
                Alternatively, fill out our lead capture form and we will place
                you with the right topbroker.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-lg bg-soft">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-12 col-lg-8">
              <p className="text-center mb-4 ">We'd love to hear from you.</p>
              <p className="text-center">
                <Link to="/contact/" className="btn btn-primary animate-up-2">
                  Contact us.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer></Footer>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
